import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import India from "../../images/india.png";
import Back from "../../images/back.png";
// import $ from 'jquery'
import { apiCallingPost } from "../../service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useRazorpay from "react-razorpay";

function Registerform(props) {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [contactError, setContactError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [amount, setAamount] = useState(window.sessionStorage?.getItem("amount"));
  const [razorPaymentId, setRazorPaymentId] = useState("");

  useEffect(() => {
  }, [timestamp, props]);
  const handlePayment = async (params, milliseconds) => {    
    document.getElementById("loader-reg").style.display="flex";
    return new Promise((resolve, reject) => {
      const options = {
        key: process.env.REACT_APP_RazorPayKey,
        amount: amount * 100,
        currency: "INR",
        name: "Mentoria",
        description: "",
        image: "",
        handler: function (response) {
          resolve(response)
         setTimeout(() => {
          navigate("/mc2/thankyou", {
            state: {
              razorpay_payment_id: response.razorpay_payment_id,
              timestamp: milliseconds,
              userCreationData: params,
            }
         }, 500);
        });
          return response;
        },
        prefill: {
          name: fullName,
          email: emailId,
          contact: contactNo,
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function() {
            navigate("/mc2");
          }
        }
      };
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        // navigate("/mc2/thankyou", {
        //   state: {
        //     reasonFailed: response.error.reason,
        //     timestamp: milliseconds, 
        //     userCreationData: params,
        //   }
        // });
      });
      rzp1.open();
      })
  };
  const handleChange = (event) => {
    const { id, value } = event.target;
    const emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const phonePattern = /^\d{10}$/;
    const countryCodePattern = /^\+(\d{1,2}\-)?(\d{1,4})$/;
    if (id === "name") {
      setFullName(value);
    } else if (id === "email") {
      setEmailId(value);
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    } else if (id === "countryCode") {
      setCountryCode(value);
      if (!countryCodePattern.test(value)) {
        setContactError("Please enter a valid country code.");
      } else {
        setContactError("");
      }
    } else if (id === "contact") {
      setContactNo(value);
      if (!phonePattern.test(value)) {
        setContactError("Please enter a valid 10-digit phone number.");
      } else {
        setContactError("");
      }
    }
  };

  const SubmitEvent = () => {
    var dateevent = "30-11-2024";
    var parts = dateevent.split("-");
    var eventdate = new Date(parts[2], parts[1] - 1, parts[0]).getTime();
    const dateString = new Date;
    const milliseconds = dateString.getTime();
    setTimestamp(milliseconds);
    var characters = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var mobile = /^\d{10}$/;
    let flag = true
    if (fullName === "") {
      toast.error("Please enter full name");
      document.getElementById("name").focus()
      flag = false
    }
    else if ((emailId === "") || (!characters.test(emailId))) {
      toast.error("Please enter valid email Id");
      document.getElementById("email").focus()
      flag = false
    }
    else if ((contactNo === "") || (!mobile.test(contactNo))) {
      toast.error("Please enter contact number")
      document.getElementById("contact").focus()
      flag = false
    }
    else if (flag = true) {
      let userCreationData = []
      const postData = {
        functionName: "userCreation",
        createdOn: milliseconds,
        userName: fullName,
        mobileNo: contactNo,
        emailId: emailId,
        userId: "",
        eventDate:eventdate,
        fromSource: window.sessionStorage.getItem("fromSource")
      }
      apiCallingPost(postData).then((data) => {
        if (data.data.success === "1") {
          userCreationData = data.data.result;
          handlePayment(userCreationData, milliseconds)
        }
      });
    }
  }

  const goBack = () => {
    if (props.fromPage) {
      navigate("/mc3"); 
    } else {
      navigate("/mc2");  
    }
  };
  return (
    <div className="register-right">
      <ToastContainer />
      <div className="back-to" onClick={()=>goBack()}>
        <img src={Back} alt="Back" className="img-fluid" width="30" height="30" />
      </div>
      <div className="reg-form">
        <div className="form-space">
          <label htmlFor="name" className="form-label">Full Name
            <span className="text-danger"> *</span>
          </label>
          <input type="text" className="form-control" id="name"
            value={fullName} onChange={handleChange} />
        </div>
        <div className="form-space">
          <label htmlFor="email" className="form-label">Email Address
            <span className="text-danger"> *</span>
          </label>
          <input type="email" className="form-control" id="email"
            value={emailId} onChange={handleChange} />
          {emailError && <div className="text-danger text-small">{emailError}</div>}
        </div>
        <div className="form-space">
          <label htmlFor="contact" className="form-label">Contact Number
            <span className="text-danger"> *</span>
          </label>
          <div className="input-group flex-nowrap">
            <span className="input-group-text bg-white" id="addon-wrapping">
              <img src={India} alt="India" className="img-fluid me-1" width="25" height="20" />
              {/* <input type="text" className="form-control border-none" id="countryCode" style={{ width: "80px", height: "auto" }}
                value={countryCode} onChange={handleChange} /> */}
                <select className="form-select border-none" onChange={handleChange}>
                  <option value={countryCode}> +91 </option>
                </select>
              <div className="seperator"></div>
            </span>
            <input type="text" className="form-control border-left-0" id="contact"
              aria-label="Contact" aria-describedby="addon-wrapping"
              value={contactNo} onChange={handleChange} />
          </div>
          {contactError && <div className="text-danger text-small">{contactError}</div>}
        </div>
        <button type="button" className="btn-theme mt-lg-4 mt-md-4 mt-2 mb-lg-0 
                mb-md-0 mb-2" onClick={() => SubmitEvent()}>Complete your Registration</button>
        <div className="reg-helptext">
          By continuing, you agree to Mentoria’s <a href="" className="reg-links">Terms of Service</a> and <a href="" className="reg-links">Privacy Policy</a>.
        </div>
      </div>
      <div class="loader-wrapper" id="loader-reg">
        <div className="loader">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </div>
  );
}

export default Registerform;
