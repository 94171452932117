import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import adsImg from '../../images/ads-img.png'

function HomeAds() {

  return (
    <div className='home-ads'>
        <div className='container-fluid px-0'>
                <div className='ads-div'>
                    <img src={adsImg} width={280} height={122} alt='ads-img' className='img-fluid'/>
                    <div className='ads-offer'>
                          <div className='ads-offer-top'>
                              <div className='ads-offer-inr'>
                                  <span className='ads-offer-span01'>
                                    {window.sessionStorage.getItem("amount")}
                                  </span>
                                  {window.sessionStorage.getItem("timing") != "00:01" &&
                                  <>
                                    <span className='ads-offer-span02'>INR 499</span>
                                    <span className='ads-offer-span03'>Offer ends in  
                                      {` ${window.sessionStorage.getItem("timing")} mins`}
                                    </span>
                                  </>
                                  }
                              </div>
                          </div>
                        <div className='ads-offer-bottom'>
                        30th November, 4:00 PM | Zoom Meetings
                        </div>
                        
                    </div>
                    <Link 
                    to="/mc2/register" 
                    className="btn-theme"
                    onClick={()=> window.sessionStorage.getItem("amount")}>Register Now</Link>
                </div>
            </div>
    </div>
  );
}

export default HomeAds;
