import React , {useState} from 'react';
import Calendar from '../../images/calenerpng.png'
import Clock from '../../images/clock.png'
import Online from '../../images/online.png'
import FastFillig from '../../images/fast_filling.png'
import BannerImg from '../../images/banner_two.png'
import Video1 from "../../video/Masterclass 2 .mp4";
import VideoIcon from "../../images/video.png";
function Banner() {
    const [controls, setControls] = useState(false);
    const clickVideo = ()=>{
        const video = document.getElementById('video1')
        if(video.paused) {
            setControls(true);
            document.getElementById("videoIcon").style.display = "none";
            video.play();
        }else {
            setControls(false);
            document.getElementById("videoIcon").style.display = "block";
            video.pause();
        }
    }
  return (
    <div className='banner-two'>
        <div className='container'>
                <div className='col-lg-12'>
                    <h1 className='banner-two-heading'>Struggling to find a job?</h1>
                    <p className='banner-two-para'>We can help! Join our Masterclass on Landing Your Dream Job in the Best Possible Way. Find out how to get more interview calls and the best job offer.</p>
                    <div className='banner-two-list'>
                        <div className='banner-two-list-item'>
                            <img src={Calendar} width={22} height={22} alt='calendar' className='img-fluid'/>
                            30th November, 4 PM
                        </div>
                        <div className='banner-two-list-item'>
                            <img src={Clock} width={22} height={22} alt='Clock' className='img-fluid'/>
                            2 hours 
                        </div>
                        <div className='banner-two-list-item'>
                            <img src={Online} width={22} height={22} alt='Online' className='img-fluid'/>
                            Live Session
                        </div>
                    </div>
                </div>
           
                   
            <div className='col-lg-12'>
                {/* <img src={BannerImg} className='img-fluid w-100' width="100%" height="100%" alt='banner'/> */}
                <div className="col-lg-5 m-auto">
                <div className='video-container'
                        onClick={() =>clickVideo()}  
                        >
                         
                            <video id="video1"
                                width="560"
                                height="315"
                                className="video-player"
                                controls={controls} 
                                preload="metadata"
                                style={{
                                    borderRadius: '15px',
                                    overflow: 'hidden'
                                }}
                            >
                                <source src={Video1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className='videoIcon' id="videoIcon">
                            <img src={VideoIcon} alt="VideoIcon" width="60" height="60" className='img-fluid' />
                        </div>

                        </div>
                </div>
              
                <div className='banner_two_connt'>
                <a href="/mc2/register" className='btn-theme'>Register Now</a>
                    <div className='banner_two_bar'>
                        <div className='banner_two_progress' style={{width: '80%'}}></div>
                    </div>
                    <div className='banner_book_div'><img src={FastFillig} width={28} height={35} className='img-fluid me-2'/>Fast Filling<span className='banner_line'></span> Limited Seats Remaining, <span className='banner_book'>Book NOW</span></div>
                </div>
            </div>
            </div>
    </div>
  );
}

export default Banner;
